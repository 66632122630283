<template>
    <div class="qna_detail_wrap" v-if="boardData.hasOwnProperty('wr_id')">
        <div class="title">{{$t('detail_qna')}}</div>
        <div class="qna_section">
            <div class="qna_top">
                <div class="subject">{{ boardData.updated_at }}</div>
                <div class="qna_date">{{ boardData.wr_subject }}</div>

            </div>
            <div class="qna_middle" v-if="returnNoEmptyFiles(boardData.File).length > 0">
                <button class="file_btn readonly" v-for="file in returnNoEmptyFiles(boardData.File)" :key="`qnaFile${file.a_idx}`" @click="awsS3Download('qna',file,boardData.wr_id)" >{{ file.org_name }}</button>
            </div>
            <div class="qna_content">
                <div class="qna_msg" v-html="replaceBr(boardData.wr_content)"></div>
            </div>
            <div class="admin_answer" v-if="boardData.hasOwnProperty('Comment') && boardData.Comment.cnt === 1">
                <div class="qna_top answer">
                    <div class="subject"><span class="reple">└&nbsp;&nbsp;Re</span> <span class="qna_subject" v-html="$t('answer_subject', {subject: boardData.wr_subject})"></span></div>
                    <div class="qna_date">{{ boardData.Comment.List[0].created_at }} / <span>{{ $t('admin_write') }}</span></div>
                </div>
                <div class="qna_middle" v-if="returnNoEmptyFiles(boardData.Comment.List[0].File).length > 0">
                    <button class="file_btn readonly"v-for="file in returnNoEmptyFiles(boardData.Comment.List[0].File)" :key="`qnaCommentFile${file.a_idx}`" @click="awsS3Download('qna',file,boardData.b_idx)" >{{ file.org_name }}</button>
                </div>
                <div class="qna_content">
                    <div class="qna_msg"  v-html="replaceBr(boardData.Comment.List[0].wr_content)"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import awsS3FileDownload from "@/mixins/awsS3FileDownload";

export default {
    name: "BoardDetailQnaLayout",
    mixins: [awsS3FileDownload],
    components: {
    },
    inject: ['setQnaData'],
    provide() {
        return{

        }
    },
    props: {
        boardData: {
            type: Object,
            default: () => {
                return []
            }
        },
    },
    data() {
        return{
            answerCheck: false,
        }
    },
    beforeRouterEnter() {},
    created() {
        this.setQna();
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        movePage(url) {
            this.$router.push(url);
        },
        setQna() {
            if(!util.isEmpty(this.boardData.Comment)) {
                this.setQnaData(this.boardData.Comment.cnt);
            }
        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        returnNoEmptyFiles(files=[]) {
            if(util.isEmpty(files)) {
                return []
            }
            return files.filter(file => file.a_idx != null);
        },
        awsS3Download(type, name, key) {
            this.runDownloadFileInfo(type, name, key).then(res => {
                if (!res) {
                    this.errorAlert(this.$t('file_download_error'));
                }

            })
        }
    },
    watch: {
        'boardData.wr_id': {
            deep: true,
            handler: function (val, oldVal) {
                this.setQna();
            }
        }
    },
}
</script>

<style scoped>

</style>
